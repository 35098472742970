.surfcam-view {
  margin-top: -30px;
}

@media (min-width: 1000px) {
  .forecasting {
    padding: 50px 30px !important;
    padding-bottom: 8px !important;
  }

  .orientation {
    margin-top: -20px !important;
    margin-bottom: 27px !important;
  }

  .nav-tabs {
    margin-bottom: 20px !important;
  }

  .forecasting .post-title {
    margin-bottom: 17px !important;
    padding: 14px;
  }
}

@media (max-width: 1000px) {
  .forecast-box-component {
    display: none;
  }
}

.forecast-warning {
  color: #e5dd2d;
  width: 25px;
  height: 25px;
  margin-top: -7px;
  margin-right: 5px;

  @media (max-width: 1000px) {
    width: 20px;
    height: 20px;
  }
}

.premium-module{
  max-width: 130%;
  grid-template-columns: 100px;
  grid-auto-flow: column; 
  grid-auto-columns: 202px;
}

.no-premium-module{
  width: 78%;
  grid-template-columns: 1fr 2fr 2fr;
}

.forecaster-box{
  width: 100%;
  height: fit-content;
  box-shadow: 0 8px 24px rgba(0,0,0, .15);
  border-radius: 10px;
  margin: 30px 0;
  padding: 10px;
}

.forecaster-card{
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;

  .forecaster-image{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    object-fit: cover;
    box-shadow: 0 8px 24px rgba(0,0,0, .15);
  }

  a { // Forecaster name style
    font-size: 16px;
    color: #007BFF;
    text-decoration: underline;
    font-weight: bold;
  }

  a:hover{
    color:#007BFF;
  }

  h6{ // Update text style
    font-size: 12px;
    color: #BBBBBB;
  }

  div{
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    span{
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #007BFF;

      svg{
        width: 14px;
        height: 14px;
        margin: 2px;
      }
    }

  }
  
}

.forecaster-prono{
  h1 {
    color: #212529;
    font-size: 16px;
    font-weight:bold;
  }

  p {
    font-family: poppins;
    font-size: 14.4px;
  }

  span {
    white-space: pre-line;
    user-select: none;
  }
}

.premium-alert{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  h1{
    text-align: center;
    font-size: 1.7em;
    font-weight: bold;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: #000;
  }

  p{
    font-size: 1.3em;
    text-align: center;
  }

  .premium-button-box{
    vertical-align: middle;
    text-align: center;
    margin: 0;
    text-align: center;

    a{
      padding: 10px 25px;
      background-color: #32c943;
      border-radius: 5px;
      color: #fff;
    }
  }
}

.forecast-table-lakes{
  display: grid;
  grid-template-columns: auto auto auto;

  @media(max-width: 999px){
    grid-template-columns: 33.3%, 33.3%, 33.3%;
  }

  div{
    border-top: 1px solid #e4e7ed;
    padding: 10px 10px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  div:nth-child(-n+3){
    border: none;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .day{
    padding-top: 20px;
    border-top: 2px solid rgb(228, 231, 237);
    font-weight: 700;
    text-transform: capitalize;
    grid-column: span 3;
    position: relative;
  }

  // .div div{
  //   position: absolute;
  //   height: 125px;
  // }

  
  .actual{
    background: rgba(130, 128, 128, 0.05);
  }

  .forecast-time{
    color: #828080;
    span{
      width: 3ch;
    }
  }

}

.new-forecast-table{
  display: grid;
  grid-template-columns: repeat( 6, auto );

  @media(max-width: 999px){
    grid-template-columns: 30% 45% auto auto auto auto;
  }

  div{
    border-top: 1px solid #e4e7ed;
    padding: 10px 10px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  div:nth-child(-n+6){
    border: none;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .day{
    padding-top: 20px;
    border-top: 2px solid rgb(228, 231, 237);
    font-weight: 700;
    text-transform: capitalize;
    grid-column: span 6;
    position: relative;

    div{
      position: absolute;
      height: 125px;
      width: 100%;
      bottom: -130px;
      z-index: 999;
      // backdrop-filter: blur(4px);
      transform: rotate3d(0,0,0,0);
    }
  }

  .actual{
    background: rgba(130, 128, 128, 0.05);
  }

  .forecast-time{
    color: #828080;
    span{
      width: 3ch;
    }
  }

  .forecast-rating{
    width: max-content!important;
  }
  //SO is the default position of the icon 
}

.arrow-direction-S{transform: rotate(315deg);}
.arrow-direction-SE{transform: rotate(260deg);}
.arrow-direction-NE{transform: rotate(190deg);}
.arrow-direction-N{transform: rotate(495deg);}
.arrow-direction-NO{transform: rotate(450deg);}
.arrow-direction-O{transform: rotate(400deg);}
.arrow-direction-E{transform: rotate(225deg);}

.terral{color: #27AE61 !important} 
.cruzado-terral{color: #EDCD1C !important} 
.cruzado-maral{color: #E67F22 !important} 
.cruzado{color: #E67F22 !important}
.maral{color: #E84C3D !important}

.swell-direction-optimum{color: #27AE61 !important}
.swell-direction-favorable{color: #EDCD1C !important}
.swell-direction-unfavorable{color: #E84C3D !important}
.swell-direction-undefined{color: #000 !important}

.bg-swell-direction-optimum{background: #27AE61 !important}
.bg-swell-direction-favorable{background: #EDCD1C !important}
.bg-swell-direction-unfavorable{background: #E84C3D !important}
.bg-swell-direction-undefined{background: #fff !important}

.wind-terral{background: #27AE61 !important} 
.wind-cruzado-terral{background: #EDCD1C !important} 
.wind-cruzado-maral{background: #E67F22 !important} 
.wind-cruzado{background: #E67F22 !important}
.wind-maral{background: #E84C3D !important}

.table-container{
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  margin-top: 20px;

  @media (max-width: 1000px) {

    overflow-x: scroll;
  }

  p{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }

}

.spot-aditional-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.surf-school-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.vertical-divider {
  width: 1px;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 1000px) {
    display: none;
  }
}

@media(max-width: 1000px){
  .spot-component-container{
    padding-top: 1.5rem !important;
  }
}

.still-watching-container {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.still-watching-title {
  font-weight: 500;
  font-size: large;
  color: #203656;
  margin: 0 auto;
}

.still-watching-button {
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 800;
  font-size: 0.8em;
  margin: 10px 0 10px 0;
  margin-right: 20px;
  width: 100%;
  background-color: #004989;
  color: #fff;
  border-radius: 5px;
}

.still-watching-button-premium {
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 800;
  font-size: 0.8em;
  margin: 10px 0 10px 0;
  margin-right: 20px;
  width: 100%;
  background-color: #ffa53c;
  color: #000;
  border-radius: 5px; 
}