.find-spot-nav {
    display: flex;
    border-radius: 5px;
    width: 30%;
    padding-left: 0px;
    padding: 0px 3px;
    margin: 0;
}

.find-spot-nav li:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-left: 1px solid #425781;
}
.find-spot-nav li:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.find-spot-nav li {
    text-align: center;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 0.8em !important;
    width: 100%;
    cursor: pointer;
    padding: 10px 15px;
    vertical-align: middle;
    list-style: none;
    background-color: #fff !important;
    color: #425781;
    border-top: 1px solid #425781;
    border-bottom: 1px solid #425781;
    border-right: 1px solid #425781;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
}

.find-spot-nav li.active {
    background-color: #425781 !important;
    color: #fff;
    border: 1px solid #425781;
}

.find-spot-nav li.deactive {
    background-color: rgb(66 87 129 / 30%) !important;
    color: #fff;
}

.find-spot-nav li svg {
    height: 1.3em;
}

.title-container .find-spot-title {
    color: #203656;
    font-size: large;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
    margin-right: 35px;
    margin-bottom: 0;
}

.title-container {
    display: flex;
    align-items: center;
}

@media(max-width: 1000px){
    .title-container .find-spot-nav { width: 100%; max-width: 300px; }

    .title-container .find-spot-title { margin-right: 15px; }
}