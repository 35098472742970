#accessView{
  height: 100vh;
  .row{
    height: 100vh;
  }
}

.instagram p {
  display: none !important;
}

a {
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.thumb {
  max-height: 500px;
  border-radius: 8px !important;
}

video {
  border-radius: 10px;
}

.button-white {
  background-color: #fff;
  border-radius: 2em;
  padding: 9px 20px;
  margin-top: 5px;
  font-weight: 600;
  border-style: none;
  color: #203656;
}

.button-white:hover {
  color: #203656 !important;
  text-decoration: none !important;
}

.nav-link.active {
  background: linear-gradient(to right, #395582 0%, #425781 51%, #425781 100%) !important;
}

.nav-link {
  font-weight: 500 !important;
  color: #203656 !important;
}

.chatbot-section {
  background-image: url("./assets/home/banner-chatbot.png");
  background-size: cover;
  padding: 10px 40px;
  margin: 30px 0px;
  height: 150px;

  .fill-background {
    content: "";
    background: #203656;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.6;
    top: 0;
    position: absolute;
    width: 100%;
    border-radius: 5px;
  }

  p {
    float: right;
    color: #fff;
    font-size: 1.3em;
    position: absolute;
    right: 60px;
    z-index: 99;
    top: 30px;
    font-family: "Poppins", sans-serif;
  }
  button {
    position: absolute;
    right: 60px;
    top: 80px;
  }
}

.title-location {
  cursor: pointer;
  font-size: 0.6em;
  display: block;
  margin-top: 10px;
  margin-left: 0;

  path {
    stroke: #007bff;
  }
}

.forecasting {
  .orientation {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
    color: #203656;
  }

  .post-title {
    font-size: 1em;
    margin-bottom: 10px !important;
    background-color: #4257810d;
    padding: 12px;
    border-radius: 4px;
    color: #203656 !important;
  }

  .highlighted {
    font-weight: bold;
  }
}

.wall-title {
  color: #fff !important;
  font-size: 1.7em !important;
}

.details {
  text-align: left !important;
  top: 75% !important;
  left: 32% !important;
  @media (max-width: 1000px) {
    transform: translate(0%, -50%) !important;
  }
}

.chatbot-section a {
  position: absolute !important;
  right: 5% !important;
  top: 45% !important;
}

.button {
  appearance: none;
  background: #16a34a;
  border-radius: 0.25em;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  height: 3em;
  line-height: 3em;
  padding: 0 1em;

  &:hover {
    background-color: lighten(#16a34a, 2%);
  }
}

// -------------- DETAILS MODAL

.details-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  max-width: 90%;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  z-index: 9999999;

  .form-group {
    svg {
      position: absolute;
      right: 50px;
      margin-top: -25px;
    }
  }

  // -------------- CLOSE

  a {
    border: 1px solid #425781;
    padding: 7px 25px;
    color: #425781 !important;
    /* margin-top: 10px; */
    border-radius: 1em;
    margin: 10px;
    font-weight: 500;
  }

  @media (max-width: 500px) {
    a {
      display: grid;
    }
  }

  @media (max-width: 900px) {
    .post-tabs {
      margin-bottom: -280px !important;
    }
  }

  .active {
    background-color: #425781 !important;
    color: #fff !important;
  }

  .details-modal-close {
    align-items: center;
    color: #111827;
    display: flex;
    height: 4.5em;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 4.5em;

    svg {
      display: block;
    }
  }

  // -------------- TITLE

  .details-modal-title {
    color: #111827;
    padding: 1.5em 2em;
    pointer-events: all;
    position: relative;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: normal;
    }
  }

  // -------------- CONTENT

  .details-modal-content {
    padding: 2em;
    padding-top: 1em;
    pointer-events: all;
    overflow: auto;
  }
}

// -------------- OVERLAY

.details-modal-overlay {
  transition: opacity 0.2s ease-out;
  pointer-events: none;
  background: rgba(#0f172a, 0.8);
  z-index: 100;
  position: fixed;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  details[open] & {
    pointer-events: all;
    opacity: 0.5;
  }
}

// -------------- DETAILS

details {
  summary {
    list-style: none;

    &:focus {
      outline: none;
    }
    &::-webkit-details-marker {
      display: none;
    }
  }
}

// -------------- OTHER

code {
  font-family: Monaco, monospace;
  line-height: 100%;
  background-color: #2d2d2c;
  padding: 0.1em 0.4em;
  letter-spacing: -0.05em;
  word-break: normal;
  border-radius: 7px;
  color: white;
  font-weight: normal;
  font-size: 1.75rem;
  position: relative;
  top: -2px;
}

.container {
  text-align: center;
  max-width: 40em;
  padding: 2em;

  > h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: normal;
    color: #111827;
  }

  > p {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  sup {
    font-size: 1rem;
    margin-left: 0.25em;
    opacity: 0.5;
    position: relative;
  }
}

.button-form {
  width: 100%;

  h6 {
    font-size: 0.7em;
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
    line-height: 21px;
    color: #656565;
  }

  button {
    width: 100%;
    background-color: #425781;
    padding: 15px;
    color: #fff !important;
    border-radius: 5px;
    border: none;
  }
}

.modal-navbar {
  margin-top: 40px;
}

.logo-modal {
  margin-top: 20px;
}

.extra-links {
  text-align: center;
  padding: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
  width: 100%;

  div{
    float: left;
    width: 100%;

    span {
      float: left;
      width: 100%;
    }

    a {
      border: none;
      color: rgb(0, 123, 255) !important;
      float: left;
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 0px !important;
      margin: 0;
      width: 100%;
    }
  }
  div:nth-of-type(2) {
    margin-top: 17px;
  }
}

.instagram-feed img {
  border-radius: 5px !important;
}

.navbar-brand {
  img {
    margin-left: -14px;
  }
}

.navbar-notificationsCounter{
  position: absolute;
  margin-top: 5px;
  font-size: 12px;
  margin-left: 9px;
  background-color: #D01322;
  color: white;
  border-radius: 11px;
  padding: 0px 3px;
  min-width: 27px;
  text-align: center;
}

.navbar-notificationsButton{
  display: inline-flex !important;
  font-size: 33px;
  margin-right: 7px;
  margin-top: 3px;
}

.navMobile-notificationsCounter{
    position: absolute;
    margin-top: -31px;
    font-size: 16px;
    margin-left: 25px;
    background-color: #D01322;
    color: white;
    border-radius: 11px;
    padding: 0px 3px;
    min-width: 27px;
    text-align: center;

    @media(max-width: 1000px){
      margin-top: -8px;
      margin-left: 16px;
    }
}

.navMobile-notificationsButton{
  color: #203656;
  width: 60px;
}

@media (min-width: 1000px) {
  .left-align {
    text-align: right !important  ;
  }
  .navbar-brand {
    width: 20% !important;
    img {
      width: 85%;
    }
  }

  body.down .header-default.clone,
  body.down .header-personal nav.clone,
  body.down .header-classic .header-bottom.clone {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .modal-navbar {
    display: flex !important;
    margin-top: 20px !important;
    text-align: center !important;
    width: 100% !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-bottom: -10px;
    margin-top: -10px;

    a {
      padding: 7px 12px !important;
      font-size: 0.9em;
      width: 100%;
    }
  }

  .details-modal-content {
    height: 100%;
    margin-top: -20px;
    background-color: #fff;
    z-index: 9999999;
  }

  .details-modal {
    .details-modal-title {
      padding: 20px;
      overflow: scroll;
    }

    .logo-modal img {
      width: 60%;
      height: 60%;
      object-fit: contain;
      padding: 1px;
    }
  }

  .canvas-menu .vertical-menu li.openmenu .switch {
    position: absolute !important;
  }

  .left-mobile {
    left: 0 !important;
    top: 65% !important;
    z-index: 99999 !important;
  }

  .slick-slider {
    max-height: 400px !important;
  }

  .thumb {
    height: 400px !important;
  }

  .thumb:after {
    border-radius: 10px !important;
    height: 100% !important;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
    z-index: 99;
  }

  .canvas-menu {
    z-index: 9999999 !important;
  }

  .icon-button {
    background: #425781 !important;
  }

  .navbar-brand {
    img {
      max-width: 190px;
    }
  }
}

.avatar {
  display: inline-flex !important;
  align-items: center !important;

  .avatar-icon {
    background-image: url("./assets/profile/icon-default-profile.jpg");
    height: 40px;
    width: 40px;
    background-size: cover;
    border-radius: 50%;
    margin-left: 10px;
  }
}

.burger-menu {
  position: absolute;
  top: 15px;
  right: 22px;
  width: 45px;
  height: 45px;
}

.logo-side-bar {
  text-align: center;
  width: 100%;
  .avatar-icon {
    background-image: url("./assets/profile/icon-default-profile.jpg");
    height: 70px;
    width: 70px;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 25px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-size: 1em;
    margin-bottom: 20px;
  }
}

.canvas-menu .logo {
  margin-bottom: 40px !important;
}

.logo-footer {
  bottom: 110px;
  max-width: 200px;
  margin-top: 30px;
}

.social-icons {
  text-align: center !important;
  margin-top: 15px;
}

.social-icons-sidebar {
  text-align: center !important;
  bottom: 80px !important;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.dropdown-toggle::after {
  margin-left: 1em !important;
  border-top: 0.7em solid !important;
  border-right: 0.7em solid transparent !important;
  border-left: 0.7em solid transparent !important;
}

.section-title {
  
  span {
    margin-left: 5px;
  
    span {
      position: absolute;
      height: 29px;
      width: 40%;
    }
  }
}

@media(max-width: 1000px){
  .mobile-mb-0 { 
    margin-bottom: 0 !important;
    * { margin-bottom: 0; }
  }
  .mobile-m-1em {margin: 1em !important}
}

.up-margin{
  margin-top: 0px !important;
}

.up-margin-camrewind-clip{
  margin-top: 20px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.canvas-menu {
  box-shadow: none !important;
  z-index: 999999;
}

.featured-post-xl .thumb:after {
  z-index: 9;
}

.canvas-menu .vertical-menu li .switch {
  z-index: 9999999;
}

.left-mobile {
  z-index: 99 !important;
}

.details-modal .details-modal-close {
  pointer-events: all !important;
  z-index: 99999;
  cursor: pointer;
}

.header-default.clone {
  z-index: 999999;
}

.dropdown-item:hover {
  margin-left: 0px !important;
}

.icons-side-bar {
  margin-top: 25px;
}

.link-in-text {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: contents !important;
}

.error-text {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  color: #dd7070;
}

.cover-box-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.clean-links {
  border: none !important;
  padding: 0 !important;
  color: #425781 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  font-weight: initial !important;
}

.header-default .navbar-nav {
  margin-left: 0px !important;
}

iframe {
  display: none !important;
}

.ad-manager-container {
  display: flex;
  gap: 10;
  justify-content: center;
  align-items: center;
  margin: 2em 0;
  min-width: 970px;
  min-height: 90px;
  iframe {
    display: inline !important;
  }

  @media(max-width: 1025px) {
    min-width: 320px;
    min-height: 100px;
  }
}

#hubspot-messages-iframe-container {
  visibility: hidden;
  margin-right: 80px;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    height: 80% !important;
    margin-bottom: 75px;
    margin-right: 0px;
  }
}

.my-anchor-css-class{
  color: #007bff;
}

.header-default { 
  background: #fff;
}

.popup-overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999 !important;
  background: rgba(66, 87, 129, 0.4);
}